import { useState, useEffect, useRef } from "react";
import { Grid, Card, Icon, Alert, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "global/components/DashboardNavbar";
import Footer from "global/components/Footer";
import { UsuarioService } from "services/usuarios";
import { useAuth } from "context/AuthContext";

function MiCuenta() {
    const { currentUser } = useAuth();
    const [datosCargados, setDatosCargados] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [error, setError] = useState("");
    const [nombre, setNombre] = useState("");
    const [nombreError, setNombreError] = useState(false);
    const [apellido1, setApellido1] = useState("");
    const [apellido1Error, setApellido1Error] = useState(false);
    const [apellido2, setApellido2] = useState("");
    const [apellido2Error, setApellido2Error] = useState(false);
    const [telefono, setTelefono] = useState("");
    const [telefonoError, setTelefonoError] = useState(false);
    const [correoElectronico, setCorreoElectronico] = useState("");
    const [direccion, setDireccion] = useState("");
    const [direccionError, setDireccionError] = useState(false);
    const [guardando, setGuardando] = useState(false);
    const timeout = useRef(null);

    //Cambio de Clave
    const [claveActual, setClaveActual] = useState("");
    const [claveActualError, setClaveActualError] = useState(false);
    const [claveNueva, setClaveNueva] = useState("");
    const [claveNuevaError, setClaveNuevaError] = useState(false)
    const [confirmacionClave, setConfirmacionClave] = useState("");
    const [confirmacionClaveError, setConfirmacionClaveError] = useState(false);
    const [mensajeClave, setMensajeClave] = useState("");
    const [errorClave, setErrorClave] = useState("");
    const timeoutClave = useRef(null);
    const [cargandoClave, setCargandoClave] = useState(false);

    const CerrarMensaje = () => {
        setMensaje("");
        setError("");
    }

    const MostrarAlerta = (msg, tipo) => {
        if (tipo == "mensaje") {
            setMensaje(msg);
        }
        else {
            setError(msg);
        }

        timeout.current = setTimeout(() => {
            setMensaje("");
            setError("");
        }, 12000);
    }

    const CerrarMensajeClave = () => {
        setMensajeClave("");
        setErrorClave("");
    }

    const MostrarAlertaClave = (msg, tipo) => {
        if (tipo == "mensaje") {
            setMensajeClave(msg);
        }
        else {
            setErrorClave(msg);
        }

        timeoutClave.current = setTimeout(() => {
            setMensaje("");
            setError("");
        }, 12000);
    }

    useEffect(() => {

        UsuarioService.consultarPerfil(currentUser).then(resp => {
            if (resp) {
                setDatosCargados(true);
                if (resp?.nombre) {
                    setNombre(resp?.nombre);
                }
                if (resp?.apellido1) {
                    setApellido1(resp?.apellido1);
                }
                if (resp?.apellido2) {
                    setApellido2(resp?.apellido2);
                }
                if (resp?.telefono) {
                    setTelefono(resp?.telefono);
                }
                if (resp?.correoElectronico) {
                    setCorreoElectronico(resp?.correoElectronico);
                }
                if (resp?.direccion) {
                    setDireccion(resp?.direccion);
                }
            }
        }).catch(err => {
            console.log(err);
            MostrarAlerta("Ocurrió un error al obtener información del perfil.")
        })

        return () => { }
    }, [])

    const ValidaNumero = (e, c) => {
        console.log(e.which)
        var key = e.key;
        var regex = /[0-9]/;
        if (!regex.test(key)) {
            e.preventDefault();
        }
    }

    const Actualizar = () => {
        if (guardando)
            return;

        setNombreError(false);
        setApellido1Error(false);
        setApellido2Error(false);
        setDireccionError(false);
        setTelefonoError(false);

        let err = "";
        if (!nombre || nombre == "") {
            err += "* Nombre";
            setNombreError(true);
        }
        if (!apellido1 || apellido1 == "") {
            err += "* Primer Apellido";
            setApellido1Error(true);
        }
        if (!telefono || telefono == "") {
            err += "* telefono";
            setTelefono(true);
        }
        if (!direccion || direccion == "") {
            err += "* Dirección";
            setDireccionError(true);
        }

        if (err != "") {
            const msg = "Debe verificar los siguiente campos:" + err;
            MostrarAlerta(msg, "error");
            return;
        }

        const body = {
            idUsuario: currentUser,
            nombre: nombre,
            apellido1: apellido1,
            apellido2: apellido2,
            telefono: telefono,
            correoElectronico: "",
            direccion: direccion,
            clave: ""
        }

        setGuardando(true);
        UsuarioService.actualizar(body).then(res => {
            setGuardando(false);
            if (res.success) {
                MostrarAlerta("Perfil actualizado", "mensaje");
                return;
            }

            if (res.error) {
                MostrarAlerta(res.error, "error");
                return;
            }

            MostrarAlerta("Ocurrió un error al actualizar perfil. Inténtelo de nuevo más tarde.")

        }).catch(err => {
            setGuardando(false);
            console.log(err);
            MostrarAlerta("Ocurrió un error al actualizar perfil. Inténtelo de nuevo más tarde.")
        });
    }

    const CambiarClave = () => {
        if (cargandoClave)
            return;

        setClaveActualError(false);
        setClaveNuevaError(false);
        setConfirmacionClaveError(false);

        let errClave = "";
        if (!claveActual || claveActual == "") {
            errClave += "*Clave Actual ";
            setClaveActualError(true);
        }
        if (!claveNueva || claveNueva == "") {
            errClave += "*Clave nueva ";
            setClaveNuevaError(true);
        }
        if (!confirmacionClave || confirmacionClave == "") {
            errClave += "*Confirmación de Clave ";
            setConfirmacionClaveError(true);
        }

        if (errClave != "") {
            const msgError = "Debe verificar los siguiente campos: " + errClave;
            MostrarAlertaClave(msgError, "error");
            return;
        }

        if ((claveNueva != "" && confirmacionClave != "") && (claveNueva != confirmacionClave)) {
            setClaveNuevaError(true);
            setConfirmacionClaveError(true);
            MostrarAlertaClave("Clave nueva y Confirmación de Clave no coinciden.", "error");
            return;
        }

        const data = {
            idUsuario: currentUser,
            claveActual: claveActual,
            claveNueva: claveNueva
        };

        setCargandoClave(true);
        UsuarioService.cambiarClave(data).then(res => {
            setCargandoClave(false)
            if (res.success) {
                MostrarAlertaClave("Se ha modificado la clave correctamente.", "mensaje");
                setClaveActual("");
                setClaveNueva("");
                setConfirmacionClave("");
                return;
            }
            if (res.error) {
                MostrarAlertaClave(res.error, "error");
                setClaveActual("");
                setClaveNueva("");
                setConfirmacionClave("");
                return;
            }

            MostrarAlertaClave("Ocurrió un error al modificar clave. Inténtelo de nuevo más tarde.", "error");
            return;

        }).catch(err => {
            setCargandoClave(false);
            MostrarAlertaClave("Ocurrió un error al modificar clave. Inténtelo de nuevo más tarde.", "error");
        })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                </MDTypography>
            </MDBox>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <MDBox
                                py={2}
                                px={2}
                                variant="contained"
                                bgColor="dark"
                                borderRadius="lg"
                                sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <MDTypography variant="h6" color="white">
                                            <Icon fontSize="medium" sx={{ mx: 1 }}>contact_emergency</Icon>
                                            Mi Información
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            {!datosCargados &&
                                <MDBox pt={3} m={3}>
                                    <MDBox>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <Skeleton variant="text" />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <Skeleton variant="text" />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <Skeleton variant="text" />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    <MDBox mt={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <Skeleton variant="text" />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <Skeleton variant="text" />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    <MDBox mt={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <Skeleton variant="rectangular" height={60} />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    {datosCargados &&
                                                        <MDButton variant="gradient" color="dark" fullWidth>Actualizar Información</MDButton>
                                                    }
                                                    {!datosCargados &&
                                                        <MDTypography variant="caption">Cargando información del perfil...</MDTypography>
                                                    }
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </MDBox>
                            }
                            {datosCargados &&
                                <MDBox pt={3} m={3}>
                                    {(mensaje || error) &&
                                        <Alert sx={{ my: 2 }} severity={mensaje != "" ? "success" : "error"} onClose={() => CerrarMensaje()}>
                                            {mensaje || error}
                                        </Alert>
                                    }
                                    <MDBox>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <MDInput onChange={(e) => setNombre(e.target.value)} value={nombre} error={nombreError} required type="text" label="Nombre" variant="outlined" fullWidth />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <MDInput onChange={(e) => setApellido1(e.target.value)} value={apellido1} error={apellido1Error} required type="text" label="Primer Apellido" variant="outlined" fullWidth />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <MDInput onChange={(e) => setApellido2(e.target.value)} value={apellido2} error={apellido2Error} type="text" label="Segundo Apellido" variant="outlined" fullWidth />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    <MDBox mt={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <MDInput inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onChange={(e) => setTelefono(e.target.value)} onKeyPress={(event) => ValidaNumero(event, '0123456789.')} value={telefono} error={telefonoError} required type="text" label="Teléfono" variant="outlined" fullWidth />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <MDInput value={correoElectronico} readonly error={false} required type="text" label="Correo Electrónico" variant="outlined" fullWidth />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    <MDBox mt={2}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    <MDInput onChange={(e) => setDireccion(e.target.value)} value={direccion} error={direccionError} required multiline rows={5} label="Dirección" variant="outlined" fullWidth />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDBox>
                                                    {datosCargados &&
                                                        <MDButton onClick={Actualizar} disabled={guardando} variant="gradient" color="dark" fullWidth>
                                                            {guardando ? "Actualizando Información..." : "Actualizar Información"}
                                                        </MDButton>
                                                    }
                                                    {!datosCargados &&
                                                        <MDTypography variant="caption">Cargando información del perfil...</MDTypography>
                                                    }
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </MDBox>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <MDBox
                                py={2}
                                px={2}
                                variant="contained"
                                bgColor="dark"
                                borderRadius="lg"
                                sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <MDTypography variant="h6" color="white">
                                            <Icon fontSize="medium" sx={{ mx: 1 }}>pin</Icon>
                                            Cambiar Contraseña
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={1} m={3}>
                                {(mensajeClave || errorClave) &&
                                    <Alert sx={{ my: 2 }} severity={mensajeClave != "" ? "success" : "error"} onClose={() => CerrarMensajeClave()}>
                                        {mensajeClave || errorClave}
                                    </Alert>
                                }
                                <MDBox>
                                    <MDInput value={claveActual} onChange={(e) => setClaveActual(e.target.value)} error={claveActualError} required type="password" label="Clave Actual" variant="outlined" fullWidth />
                                </MDBox>
                                <MDBox mt={1}>
                                    <MDInput value={claveNueva} onChange={(e) => setClaveNueva(e.target.value)} error={claveNuevaError} required type="password" label="Clave Nueva" variant="outlined" fullWidth />
                                </MDBox>
                                <MDBox mt={1}>
                                    <MDInput value={confirmacionClave} onChange={(e) => setConfirmacionClave(e.target.value)} error={confirmacionClaveError} required type="password" label="Confirmación de Clave Nueva" variant="outlined" fullWidth />
                                </MDBox>
                                <MDBox mt={1}>
                                    <MDButton disabled={cargandoClave} onClick={CambiarClave} variant="gradient" color="dark" fullWidth>
                                     {cargandoClave? "Cambiand la Clave..." : "Cambiar Clave"}
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default MiCuenta;
