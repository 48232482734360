import { useState, useRef } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import { Alert, Autocomplete, Button, ButtonGroup, FormControlLabel, FormLabel, Grid, Icon, Radio, RadioGroup, Typography } from "@mui/material";
import MDButton from "components/MDButton";

import { useAuth } from "context/AuthContext";
import { PaqueteService } from "services/paquetes";

const Calculadora = () => {
    const { currentUser } = useAuth()
    const [mensaje, setMensaje] = useState("");
    const [error, setError] = useState("");
    const [guardando, setGuardando] = useState(false);
    const timeout = useRef(null)

    //tracking
    const [tracking, setTracking] = useState("");
    const [trackingError, setTrackingError] = useState(false);

    //descripcion
    const [descripcion, setDescripcion] = useState("");
    const [descripcionError, setDescripcionError] = useState(false);

    //comercio
    const [comercio, setComercio] = useState("")
    const [comercioError, setComercioError] = useState(false)

    //factura
    const [factura, setFactura] = useState("");
    const [facturaError, setFacturaError] = useState(false);

    //transporte
    const [transporte, setTransporte] = useState("Aéreo");
    const [transporteError, setTransporteError] = useState(false);

    const [medida, setMedida] = useState("Lbs");
    const [peso, setPeso] = useState('');
    const [precio, setPrecio] = useState('');
    const [pesoError, setPesoError] = useState(false);
    const [precioError, setPrecioError] = useState(false);
    const [total, setTotal] = useState('');

    const CerrarMensaje = () => {
        setMensaje("");
        setError("");
    }

    const MostrarAlerta = (msg, tipo) => {
        if (tipo == "mensaje") {
            setMensaje(msg);
        }
        else {
            setError(msg);
        }

        timeout.current = setTimeout(() => {
            setMensaje("");
            setError("");
        }, 12000);
    }

    const ValidaNumero = (e, c) => {
        console.log(e.which)
        var key = e.key;
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            e.preventDefault();
        }
    }

    const LimpiarControles = () => {
        setTracking("");
        setDescripcion("");
        setComercio("");
        setFactura("");
        setTransporte("");
    }

    const Guardar = () => {
        if (guardando)
            return;

        setTrackingError(false);
        setComercioError(false);
        setFacturaError(false);
        setDescripcionError(false);
        setTransporteError(false);

        let err = "";
        if (!tracking || tracking == "") {
            setTrackingError(true);
            err += "| Tracking |";
        }
        if (!comercio || comercio == "") {
            setComercioError(true);
            err += "| Comercio |";
        }
        if (!descripcion || descripcion == "") {
            setDescripcionError(true);
            err += "| Descripción |";
        }
        if (!factura || factura == "") {
            setFacturaError(true);
            err += "| Factura |";
        }
        if (!transporte || transporte == "") {
            setTransporteError(true);
            err += "| Transporte |";
        }

        if (err != "") {
            let mensajeError = "Los siguientes campos son requeridos: " + err;
            MostrarAlerta(mensajeError, 'error');
            return;
        }

        const body = {
            idPaquete: "",
            idUsuario: currentUser,
            trackingId: tracking,
            descripcionPaquete: descripcion,
            valorFacturaUsd: factura,
            comercio: comercio,
            transporte: transporte
        }

        setGuardando(true);
        PaqueteService.registrar(body).then(resp => {
            if (resp.success) {
                LimpiarControles();
                setGuardando(false);
                MostrarAlerta("Paquete se ha registrado correctamente.", "mensaje");
                return;
            }

            MostrarAlerta(resp.error, "error");
        }).catch(err => {
            console.log(err);
            setGuardando(false);
            MostrarAlerta("Ocurrió un error al registrar paquete.", "error");
        })
    }

    const handleMedida = (event) => {
        setMedida(event.target.value);
    }

    const calcular = () => {
        setTotal("$0.00");
        setPrecioError(false);
        setPesoError(false);

        if (peso == "") {
            setPesoError(true);
        }

        if (peso == "") {
            return;
        }

        if (peso != "") {
            let precioInicial= 0;
            if(precio != "")
               precioInicial= precio;

            let pesoNum = Number(peso);
            const precioNum = Number(precioInicial);
            if (medida == "Kg") {
                pesoNum = pesoNum * Number(2.2046);
            }

            const totalPorPeso = pesoNum * 8.99;
            const totTexto = Number(totalPorPeso + precioNum).toFixed(2).toString();
            setTotal("$" + totTexto);
        }
        else {
            setTotal("$0.00")
        }
    }

    return (<Card sx={{ mt: 5 }}>
        <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="contained"
            bgColor="dark"
            borderRadius="lg"
            coloredShadow="dark"
        >
            <MDTypography variant="h6" color="white">
                Calculadora
            </MDTypography>
        </MDBox>
        <MDBox mt={2} mx={5}>
            {(mensaje || error) &&
                <Alert severity={mensaje != "" ? "success" : "error"} onClose={() => CerrarMensaje()}>
                    {mensaje || error}
                </Alert>
            }
            <MDBox mb={2}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            disableClearable
                            autoComplete={false}
                            autoSelect={true}
                            value={transporte}
                            sx={{ mt: { sm: '15px', md: '10px' } }}
                            label="Tipo de Transporte"
                            options={['Aéreo', 'Marítimo']}
                            onChange={(event, newValue) => {
                                setTransporte(newValue)
                            }}
                            size="small"
                            renderInput={(params) => <MDInput error={transporteError} required fullWidth label="Tipo de Transporte" {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} pl={5} pt={1}>
                        {transporte == "Aéreo" &&
                            <RadioGroup
                                row
                                defaultValue={medida}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={handleMedida}
                            >
                                <FormControlLabel value="Lbs" slec control={<Radio />} label="Libras" />
                                <FormControlLabel value="Kg" control={<Radio />} label="Kilos" />
                            </RadioGroup>
                        }

                    </Grid>
                </Grid>
            </MDBox>
            {transporte == "Aéreo" &&
                <MDBox mb={2}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <MDInput value={peso} onChange={(e) => setPeso(e.target.value)} error={pesoError} onKeyPress={(event) => ValidaNumero(event, '0123456789.')} required type="text" label={"Peso en " + medida} variant="standard" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDInput value={precio} onChange={(e) => setPrecio(e.target.value)} error={precioError} required sx={{ ml: { md: '15px' } }} type="text" onKeyPress={(event) => ValidaNumero(event, '0123456789.')} maxLength={5} label="Precio del Paquete ($)" variant="standard" fullWidth />
                        </Grid>
                    </Grid>
                </MDBox>
            }
            {transporte == "Aéreo" &&
                <MDBox mb={2} display="flex">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {total}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDButton onClick={calcular} disabled={guardando} sx={{ mt: { sm: '15px', md: '0' }, ml: { md: '15px' } }} color="dark" fullWidth>
                                {guardando ? "CREANDO REGISTRO..." : "CALCULAR"}
                            </MDButton>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            {transporte != "Aéreo" &&
                <MDBox mb={2} display="flex">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDButton onClick={() => window.open('https://wa.me/50672522435')} disabled={guardando} sx={{ mt: { sm: '15px', md: '0' }, ml: { md: '15px' } }} color="dark" fullWidth>

                                {guardando ? "CREANDO REGISTRO..." : "Conversemos por WhatsApp"}
                            </MDButton>
                        </Grid>
                    </Grid>
                </MDBox>
            }
        </MDBox>
    </Card>)
}

export default Calculadora;