/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "global/components/DashboardNavbar";
import Footer from "global/components/Footer";

// Dashboard components
import DefaultCard from "examples/Cards/InfoCards/DefaultCard";
import MDTypography from "components/MDTypography";
import RegistroPaquete from "./components/registroPaquete";
import { useAuth } from "context/AuthContext";
import Calculadora from "./components/calculadora";

function Dashboard() {
  const { currentUser, currentName } = useAuth()

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={{xs:1, md:3}}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <DefaultCard
              color="dark"
              icon="flight"
              title="Dirección - Aérea">
              <div style={{ textAlign: 'left', color: 'white' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      Name:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      Racecar Imports ({currentName})
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      Address:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      11350 NW 25 ST SUITE 100
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      City:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      DORAL, FLORIDA
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      State:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      Florida (FL)
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      Zip Code:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      33172
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      Phone:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      305-477-5508
                    </MDTypography>
                  </Grid>
                </Grid>
              </div>
            </DefaultCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCard
              color="dark"
              icon="directions_boat"
              title="Dirección - Marítima">
              <div style={{ textAlign: 'left', color: 'white' }}>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                      Name:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption" mx='10px' align="left" fontWeight="regular">
                      {currentName}
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                      Address 1:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption" mx='10px' align="left" fontWeight="regular">
                      8425 NW 68th ST
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                      Address 2:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption" mx='10px' align="left" fontWeight="regular">
                      Race001966
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                      City:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption" mx='10px' align="left" fontWeight="regular">
                      Miami
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      State:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption" mx='10px' align="left" fontWeight="regular">
                      Florida (FL)
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                      Zip Code:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption" mx='10px' align="left" fontWeight="regular">
                      33166
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item  xs={6} md={4}>
                    <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }}  mx='10px' fontWeight="regular">
                      Phone:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign='left'>
                    <MDTypography variant="caption"  mx='10px' align="left" fontWeight="regular">
                      305-801-1339
                    </MDTypography>
                  </Grid>
                </Grid>
              </div>
            </DefaultCard>
          </Grid>
        </Grid>
      </MDBox>
      <Grid justifyItems='center' container spacing={2}>
        <Grid item xs={0} md={4}>
          <Calculadora/>
        </Grid>
        <Grid item xs={12} md={8}>
          <RegistroPaquete />
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
