/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "global/components/DashboardNavbar";
import Footer from "global/components/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";

// Data
import usuariosTableData from "./data/usuariosTableData";

function Usuarios() {
  const { columns, rows } = usuariosTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                py={2}
                px={2}
                variant="contained"
                bgColor="dark"
                borderRadius="lg"
                sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10} lg={10}>
                    <MDTypography variant="h6" color="white">
                      <Icon fontSize="medium" sx={{ mx: 1 }}>people_outline</Icon>
                      Casilleros Registrados
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  canSearch={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Usuarios;
