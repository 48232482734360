import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAuth } from "context/AuthContext";
import { useState, useEffect, useRef } from "react";
import { PaqueteService } from "services/paquetes";

export default function data() {
  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(false);
  const timeout = useRef(null);
  const { currentUser } = useAuth();

  const estaCargando=()=>{
    return cargando;
  }

  useEffect(() => {
    setCargando(true);
    PaqueteService.consultarPaquetesUsuario(currentUser).then(resp => {
      setCargando(false);
      setData(resp);
    }).catch(err => {
      setCargando(false);
      console.log(err)
      alert("Ocurrió un error al cargar tus paquetes.")
    });

    return () => { }
  }, []);

  const buildStructure = () => {
    let lst = [];
    let cont = 0;
    data.forEach(element => {
      cont++;
      const row = {
        trackingId: element.trackingId,
        descripcionPaquete: element.descripcionPaquete,
        valorFacturaUsd: element.valorFacturaUsd,
        totalPaqueteUsd: element.totalPaqueteUsd == 0 ? "----" : $`${element.totalPaqueteUsd}`,
        transporte: element.transporte,
        fechaHoraCreacion: element.fechaHoraCreacion,
        ultimoEstado: element.ultimoEstado,
        custom: (<MDBox>
          <MDBox>
            <MDTypography variant="caption">Transporte: {element.transporte}</MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant="caption">Comercio: {element.comercio}</MDTypography>
          </MDBox>
        </MDBox>)
      }
      lst.push(row);
    });

    return lst;
  }

  return {
    columns: [
      { Header: "Tracking", accessor: "trackingId", align: "left" },
      { Header: "Paquete", accessor: "descripcionPaquete", align: "left" },
      { Header: "Fecha/Hora", accessor: "fechaHoraCreacion", align: "left" },
      { Header: "Información", accessor: "custom", align: "left" },
      { Header: "Estado Sistema", accessor: "ultimoEstado", align: "left" },
    ],
    rows: buildStructure(),
    procesando: estaCargando()
  };
}
