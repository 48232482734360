import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState("");
    const [currentRole, setCurretRole] = useState("");
    const [currentName, setCurrentName] = useState("");
    const [loading, setLoading] = useState(false);

    function signOut() {
        auth.signOut();
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("usuario");
        setCurrentUser("");
    }

    function login(idusuario, role, nombre) {
        setCurrentUser(idusuario);
        setCurretRole(role);
        setCurrentName(nombre)
        sessionStorage.setItem("usuario", idusuario);
    }

    useEffect(() => {
        auth.onAuthStateChanged(async user => {
            if (user) {
                setCurrentUser(user);
                var token = await user.getIdToken();
                sessionStorage.setItem("token", token);
            }
            setLoading(false)
        })

        auth.onIdTokenChanged(async user => {
            if (user) {
                sessionStorage.setItem("token", await user.getIdToken());
            }
        })

        return () => console.log("unmounting...");
    }, [])

    const value = {
        currentUser,
        currentName,
        currentRole,
        login,
        signOut
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
