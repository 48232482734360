import { api } from '../config';

export const EstadoPaquetesService = {
    registrar: async function (paquete) {
        const response = await api.request({
            url: "/api/EstadoPaquetes/CrearEstadoPaquetes",
            method: "POST",
            data: paquete,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    listar: async function () {
        const response = await api.request({
            url: `/api/EstadoPaquetes/ObtenerEstadoPaquetes`,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    consultarPorId: async function (idPaquete) {
        const response = await api.request({
            url: `/api/EstadoPaquetes/ObtenerEstadoPaquete/${idPaquete}`,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    }
}