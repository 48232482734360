/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import racecarlogowhite from "assets/images/logoRacecarNegro.png";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setAutenticado } from "../../../context";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/serviciosbg.png";

import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UsuarioService } from "services/usuarios";
import { Alert } from "@mui/material";

function Basic({ title }) {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [rememberMe, setRememberMe] = useState(false);
  const [usuario, setUsuario] = useState('')
  const [clave, setClave] = useState('')
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState("");
  const [mensaje, setMensaje] = useState("");
  const timeout = useRef(null);
  const [recuperarClave, setRecuperarClave] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [controller, dispatch] = useMaterialUIController();

  const MostrarError = (err) => {
    MostrarAlerta(err, "error");
  }

  const Validar = () => {
    if (cargando)
      return;

    if (usuario == "") {
      alert("Debe ingresar un correo electrónico válido");
      return;
    }
    if (clave == "") {
      alert("Debe ingresar una clave válida.");
      return;
    }

    let data = {
      correoElectronico: usuario,
      clave: clave
    }

    setCargando(true);
    UsuarioService.autenticar(data).then(response => {
      setCargando(false);
      console.log(response.resultado)
      if (response.success) {
        let resultado = response.resultado;
        login(resultado?.idUsuario, response.mensaje, resultado?.nombre);
        if (response.mensaje == "admin") {
          navigate("/estadoPaquetes");
        }
        else
          navigate("/dashboard");
        return;
      }

      if (response.error) {
        MostrarError(response.error);
      }

    }).catch(err => {
      setCargando(false);
      console.log(err)
      MostrarError("Ocurrió un error al verificar credenciales. Inténtelo de nuevo más tarde.");
    });
  }

  const GenerarClave = () => {
    if (cargando)
      return;

    if (usuario == "") {
      alert("Debe ingresar un correo electrónico válido");
      return;
    }

    let data = {
      correoElectronico: usuario,
      clave: ""
    }

    setCargando(true);
    UsuarioService.generarClave(data).then(response => {
      setCargando(false);
      console.log(response.resultado)
      if (response.success) {
        MostrarAlerta("Hemos enviado las instrucciones a tu correo electrónico.", "mensaje");
        VolverALogin();
        return;
      }

      if (response.error) {
        MostrarError(response.error);
      }

    }).catch(err => {
      setCargando(false);
      console.log(err)
      MostrarError("Ocurrió un error al verificar credenciales. Inténtelo de nuevo más tarde.");
    });
  }

  const MostrarAlerta = (msg, tipo) => {
    if (tipo == "mensaje") {
      setMensaje(msg);
    }
    else {
      setError(msg);
    }

    timeout.current = setTimeout(() => {
      setMensaje("");
      setError("");
    }, 12000);
  }

  const CerrarMensaje = () => {
    setMensaje("");
    setError("");
  }

  const VolverALogin = () => {
    setUsuario("");
    setClave("");
    setCargando(false);
    setRecuperarClave(false);
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          mx={2}
          mt={3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img onClick={() => window.open("https://racecarcr.com", "_self")} src={racecarlogowhite} alt="Logo" style={{ width: "10rem" }} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {(mensaje || error) &&
            <Alert sx={{ mb: 2 }} severity={mensaje != "" ? "success" : "error"} onClose={() => CerrarMensaje()}>
              {mensaje || error}
            </Alert>
          }
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput value={usuario} type="text" label="Correo Electrónico" onChange={(evt) => {
                setUsuario(evt.target.value);
              }} fullWidth />
            </MDBox>
            {!recuperarClave &&
              <>
                <MDBox mb={2} textAlign='right'>
                  <MDButton variant="text" sx={{ mr: -3 }} onClick={() => setRecuperarClave(true)} color="info">¿Olvidaste tu contraseña?</MDButton>
                  <MDInput value={clave} type="password" label="Clave" onChange={(evt) => {
                    setClave(evt.target.value);
                  }} fullWidth />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton disabled={cargando} onClick={Validar} variant="gradient" color="dark" fullWidth>
                    {!cargando ? "Ingresar" : "Verificando..."}
                  </MDButton>
                </MDBox>
                <MDTypography variant="subtitle1" mt={2} sx={{ textAlign: 'center' }}>
                  Aún no tiene una cuenta
                </MDTypography>
                <MDBox mt={2} mb={1}>
                  <MDButton onClick={() => window.open("https://racecarcr.com/afiliacion", "_self")} variant="gradient" color="light" fullWidth>
                    Afiliarse
                  </MDButton>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton onClick={() => window.open("https://racecarcr.com", "_self")} variant="gradient" color="light" fullWidth>
                    Volver al sitio principal
                  </MDButton>
                </MDBox>
              </>
            }
            {recuperarClave &&
              <>
                <MDBox mt={4} mb={1}>
                  <MDButton disabled={cargando} onClick={GenerarClave} variant="gradient" color="dark" fullWidth>
                    {!cargando ? "Restablecer mi contraseña" : "Restableciendo..."}
                  </MDButton>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton onClick={VolverALogin} variant="gradient" color="light" fullWidth>
                    Cancelar
                  </MDButton>
                </MDBox>
              </>
            }
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
