import axios from "axios";

export const api = axios.create({
    baseURL: "https://q2mti8xgac.execute-api.us-east-1.amazonaws.com/Prod"
})

const errorHandler = (error) => {
    const statusCode = error.response?.status;

    if (statusCode && statusCode !== 401) {
        console.error(error);
    }

    return Promise.reject(error);
}

api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})