import { api } from '../config';

export const UsuarioService = {
    autenticar: async function (usuario) {
        const response = await api.request({
            url: "/api/Usuarios/Autenticar",
            method: "POST",
            data: usuario,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    actualizar: async function (usuario) {
        const response = await api.request({
            url: "/api/Usuarios/ActualizarPerfil",
            method: "POST",
            data: usuario,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    consultarPerfil: async function (idUsuario) {
        const response = await api.request({
            url: `/api/Usuarios/ObtenerPerfil/${idUsuario}`,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    cambiarClave: async function (usuario) {
        const response = await api.request({
            url: "/api/Usuarios/CambiarClave",
            method: "POST",
            data: usuario,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    generarClave: async function (usuario) {
        const response = await api.request({
            url: "/api/Usuarios/GenerarClaveTemporal",
            method: "POST",
            data: usuario,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
    listar: async function () {
        const response = await api.request({
            url: "/api/Usuarios/ObtenerUsuarios",
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        });

        return response?.data;
    },
}