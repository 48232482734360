import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "global/components/Footer";
import DataTable from "./DataTable";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";

// Data
import data from "./data";

function ConsultarPaquetes() {
    const { columns, rows, procesando } = data();

    return (
        <DashboardLayout>
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                </MDTypography>
            </MDBox>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                py={3}
                                px={2}
                                variant="contained"
                                bgColor="dark"
                                borderRadius="lg"
                                sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <MDTypography variant="h6" color="white">
                                            Mis Paquetes {procesando && "- Cargando Informacion"}
                                        </MDTypography>
                                        
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={{ defaultValue: 20, entries: ['20'] }}
                                    canSearch={true}
                                    showTotalEntries={false}
                                    noEndBorder={false}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ConsultarPaquetes;
