import { useState, useEffect, useRef } from "react";
import { Grid, Card, Icon, Alert, Autocomplete } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "global/components/DashboardNavbar";
import Footer from "global/components/Footer";
import { useAuth } from "context/AuthContext";
import { EstadoPaquetesService } from "services/estadopaquetes";
import DataTable from "examples/Tables/DataTable";

function EstadoPaquetes() {
    const [rows, setRows] = useState([])
    const columns = [
        { Header: "Tracking Id", accessor: "idEstadoPaquete", align: "left" },
        { Header: "Estado", accessor: "estado", align: "left" },
        { Header: "Fecha/Hora", accessor: "fechaHora", align: "left" },
        { Header: "Info Adicional", accessor: "custom", align: "left" },
    ];
    const { currentUser } = useAuth();
    const [guardando, setGuardando] = useState(false);
    const timeout = useRef(null);

    const [mensaje, setMensaje] = useState("");
    const [error, setError] = useState("");
    const timeoutClave = useRef(null);

    const [trackingsError, setTrackingsError] = useState(false);
    const [trackings, setTrackings] = useState("")
    const [estado, setEstado] = useState('');
    const [estadoError, setEstadoError] = useState(false);

    const CerrarMensaje = () => {
        setMensaje("");
        setError("");
    }

    const MostrarAlerta = (msg, tipo) => {
        if (tipo == "mensaje") {
            setMensaje(msg);
        }
        else {
            setError(msg);
        }

        timeout.current = setTimeout(() => {
            setMensaje("");
            setError("");
        }, 12000);
    }

    const RegistrarEstados = () => {
        if (guardando)
            return;

        setEstadoError(false);
        setTrackingsError(false);

        let err = "";
        if (!trackings || trackings == "") {
            err += "*Tracking Ids ";
            setTrackingsError(true);
        }
        if (!estado || estado == "") {
            err += "*Estado ";
            setEstadoError(true);
        }

        if (err != "") {
            const msgError = "Debe verificar los siguiente campos: " + err;
            MostrarAlerta(msgError, "error");
            return;
        }

        const body = {
            trackingIds: trackings,
            estado: estado,
            usuario: currentUser
        }

        setGuardando(true);
        EstadoPaquetesService.registrar(body).then(response => {
            setGuardando(false);
            if (response.success) {
                buildStructure();
                setEstado('');
                setTrackings('');
                MostrarAlerta("Estados Registrados", "mensaje");
                return;
            }

            if (response.error) {
                MostrarAlerta(response.error, "error");
                return;
            }

            MostrarAlerta("Ocurrió un error al registrar estados. Inténtelo de nuevo más tarde.")

        }).catch(err => {
            setGuardando(false);
            console.log(err);
            MostrarAlerta("Ocurrió un error al registrar estados. Inténtelo de nuevo más tarde.")
        });
    }

    const buildStructure = () => {
        EstadoPaquetesService.listar().then(response => {
            let lst = [];
            let cont = 0;
            response.forEach(element => {
                cont++;
                const row = {
                    idEstadoPaquete: element.idEstadoPaquete,
                    estado: element.estado,
                    fechaHora: element.fechaHoraTexto,
                    custom: element.nombreUsuario != "" ? (<MDBox>
                        <MDBox>
                            <MDTypography variant="caption">Nombre: {element.nombreUsuario}</MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="caption">Correo Electrónico: {element.correoElectronico}</MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="caption">Teléfono: {element.telefono}</MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="caption">Dirección: {element.direccion}</MDTypography>
                        </MDBox>
                    </MDBox>) : "-----"
                }
                lst.push(row);
            });

            setRows(lst);
        }).catch(err => {
            console.log(err);
        })
    }
    
    useEffect(() => {
      buildStructure();
    
      return () => {
      }
    }, [])
    

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                </MDTypography>
            </MDBox>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <MDBox
                                py={2}
                                px={2}
                                variant="contained"
                                bgColor="dark"
                                borderRadius="lg"
                                sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <MDTypography variant="h6" color="white">
                                            <Icon fontSize="medium" sx={{ mx: 1 }}>library_add</Icon>
                                            Registrar Estados
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={1} m={3}>
                                {(mensaje || error) &&
                                    <Alert sx={{ my: 2 }} severity={mensaje != "" ? "success" : "error"} onClose={() => CerrarMensaje()}>
                                        {mensaje || error}
                                    </Alert>
                                }
                                <MDBox>
                                    <Autocomplete
                                        disableClearable
                                        autoComplete={false}
                                        autoSelect={true}
                                        sx={{ mt: { sm: '15px', md: '0' } }}
                                        label="Estado"
                                        value={estado}
                                        options={['En Bodega de Miami', 'En Tránsito a Costa Rica', 'Listo para Retirar en bodegas de CR', 'Entregado']}
                                        onChange={(event, newValue) => {
                                            setEstado(newValue)
                                        }}
                                        size="small"
                                        renderInput={(params) => <MDInput error={estadoError} required fullWidth label="Estado" {...params} />}
                                    />
                                </MDBox>
                                <MDBox mt={2}>
                                    <MDInput value={trackings} onChange={(e) => setTrackings(e.target.value)} error={trackingsError} required multiline rows={5} label="Lista de Trackings" variant="outlined" fullWidth />
                                </MDBox>
                                <MDBox mt={1}>
                                    <MDButton disabled={guardando} onClick={RegistrarEstados} variant="gradient" color="dark" fullWidth>
                                        {guardando ? "Registrando los estados..." : "Registrar Estados"}
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <MDBox
                                py={2}
                                px={2}
                                variant="contained"
                                bgColor="dark"
                                borderRadius="lg"
                                sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10} lg={10}>
                                        <MDTypography variant="h6" color="white">
                                            <Icon fontSize="medium" sx={{ mx: 1 }}>description</Icon>
                                            Estados Registrados
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={1} m={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    showTotalEntries={false}
                                    isSorted={false}
                                    canSearch={true}
                                    noEndBorder
                                    entriesPerPage={false}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default EstadoPaquetes;
