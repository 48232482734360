/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { PaqueteService } from "services/paquetes";

export default function data() {
  const navigate = useNavigate();
  const [data, setData] = useState([])

  useEffect(() => {
    PaqueteService.listar().then(response => {
      console.log(response)
      setData(response);
      buildStructure(response);
    }).catch((err) => console.debug(err));

    return () => {

    }
  }, []);

  const EliminarUsuario = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/panel/EliminarUsuario/ticolineapanel/e&9QzbF2DB7tg5&s/${id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(res => {
        cargarDatos();
      }).catch((err) => {
        alert("Ocurrió un error al eliminar usuario.");
        console.log(err);
      });
  }

  const cargarDatos = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/panel/ObtenerUsuarios/ticolineapanel/e&9QzbF2DB7tg5&s`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(res => {
        setData(res.data);
        buildStructure(res.data);
      }).catch(() => console.debug("Error al obtener usuarios"));
  }

  const EditarUsuario = (id) => {
    navigate(`/usuario/${id}`);
  }

  const buildStructure = () => {
    let lst = [];
    let cont = 0;
    data.forEach(element => {
      cont++;
      const row = {
        paquete: <>
          <MDBox sx={{ display: 'flex' }}>
            <Icon>email</Icon>
            <MDTypography ml={1} variant="caption">
              {element.descripcion}
            </MDTypography>
          </MDBox>
          <MDBox>
            <Icon>apartment</Icon>
            <MDTypography ml={1} variant="caption">
              {element.comercio}
            </MDTypography>
          </MDBox>
          <MDBox>
            <Icon>paid</Icon>
            <MDTypography ml={1} variant="caption">
              {element.valorFactura}
            </MDTypography>
          </MDBox>
          <MDBox>
            <Icon>local_shipping</Icon>
            <MDTypography ml={1} variant="caption">
              {element.transporte}
            </MDTypography>
          </MDBox>
        </>,
        nombre: element.nombre,
        tracking: element.trackingId,
        fechaHora: element.fechaHora,
        info: <>
          <MDBox sx={{ display: 'flex' }}>
            <Icon>email</Icon>
            <MDTypography ml={1} variant="caption">
              {element.correoElectronico}
            </MDTypography>
          </MDBox>
          <MDBox>
            <Icon>phone</Icon>
            <MDTypography ml={1} variant="caption">
              {element.telefono}
            </MDTypography>
          </MDBox>
        </>,
        direccion: <MDBox>
          <MDTypography paragraph variant='caption' sx={{ width: 150 }}>
            {element.direccion}
          </MDTypography>
        </MDBox>,
        action: (
          <MDBox ml={-1}>
            <IconButton onClick={() => EditarUsuario(element.id)} size="small" disableRipple>
              <Tooltip title='Editar Usuario' placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => EliminarUsuario(element.id)} size="small" disableRipple>
              <Tooltip title='Eliminar Usuario' placement="top">
                <Icon>close</Icon>
              </Tooltip>
            </IconButton>
          </MDBox>
        )
      }
      lst.push(row);
    });

    return lst;
  }

  return {
    columns: [
      { Header: "Tracking Id", accessor: "tracking", align: "left" },
      { Header: "Nombre", accessor: "nombre", align: "left" },
      { Header: "paquete", accessor: "paquete", align: "left" },
      { Header: "info", accessor: "info", align: "left" },
      { Header: "Fecha/Hora", accessor: "fechaHora", align: "left" },
      { Header: "Dirección", accessor: "direccion", align: "left" },
    ],
    rows: buildStructure()
  };
}