/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultCard({ color, icon, title, description, value, children }) {
  return (
    <Card sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
      background: "white",
      boxShadow: xl,
      position: "relative",
    })}>
      <MDBox p={2} mx={3} ml={-2} mt={-5} display="flex" justifyContent="center">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          px='20px'
          height="4rem"
          shadow="lg"
          borderRadius="lg"
          variant="contained"
          coloredShadow="white"
        >
          <Icon fontSize="default">{icon}</Icon>
          <MDTypography variant="caption" color="white" mx='10px' fontWeight="regular">
            {title}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25} color="black">
        {children}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultCard
DefaultCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultCard
DefaultCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultCard;
