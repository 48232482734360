/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "global/components/DashboardNavbar";
import Footer from "global/components/Footer";

// Dashboard components
import DefaultCard from "examples/Cards/InfoCards/DefaultCard";
import MDTypography from "components/MDTypography";
import { useAuth } from "context/AuthContext";
import Waze from "assets/images/logos/waze.png"
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";

function Contactenos() {
    const { currentUser, currentName } = useAuth()

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox p={{ xs: 1, md: 3 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <DefaultCard
                            color="dark"
                            icon="business"
                            title="Nuestra Dirección">
                            <div style={{ textAlign: 'left', color: 'white' }}>
                            <Grid container spacing={2}>
                                    <Grid item xs={6} md={12}>
                                        <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                                            San Juan, La Unión, Cartago 
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={12}>
                                        <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mx='10px' fontWeight="regular">
                                            <MDButton onClick={() => window.open("https://www.waze.com/en/live-map/directions/cr/san-jose/racecar-imports?place=ChIJo9IA5IjloI8RuTVyn1oimTA", "_blank")} variant="contained" color='light'>
                                                <img src={Waze} width={24} alt="waze" style={{ marginRight: '10px' }} />
                                                <MDTypography color='dark' variant='h6' sx={{ display: 'inline', verticalAlign: 'middle' }}>
                                                    Abrir dirección en Waze
                                                </MDTypography>
                                            </MDButton>
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={12} mt={2}>
                                        <iframe src="https://embed.waze.com/es/iframe?zoom=16&lat=9.903840&lon=-83.999992&pin=1&desc=1" width="600" height="450" allowfullscreen></iframe>
                                    </Grid>
                                </Grid>
                            </div>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DefaultCard
                            color="dark"
                            icon="contact_support"
                            title="¿Necesitas contactarte con nosotros?">
                            <div style={{ textAlign: 'left', color: 'white' }}>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={6} md={4}>
                                        <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mt={1} mx='10px' fontWeight="regular">
                                            Correo Electrónico:
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={6} textAlign='left'>
                                        <MDButton onClick={() => window.open("mailto:info@racecarcr.com", "_blank")} variant="contained" color='light'>
                                            <Icon>email</Icon>
                                            <MDTypography ml={1} color='dark' variant='h6' sx={{ display: 'inline', verticalAlign: 'middle' }}>
                                                info@racecarcr.com
                                            </MDTypography>
                                        </MDButton>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={6} md={4}>
                                        <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mt={1} mx='10px' fontWeight="regular">
                                            WhatsApp:
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={6} textAlign='left'>
                                        <MDButton onClick={() => window.open("https://wa.me/50672522435", "_blank")} variant="contained" color='light'>
                                            <Icon>message</Icon>
                                            <MDTypography color='dark' variant='h6' ml={1} sx={{ display: 'inline', verticalAlign: 'middle' }}>
                                                +506 7252-2435
                                            </MDTypography>
                                        </MDButton>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={6} md={4}>
                                        <MDTypography sx={{ typography: { md: 'body2', xs: 'caption' } }} mt={1} mx='5px' fontWeight="regular">
                                            ¿Necesitas más información?
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={6} textAlign='left'>
                                        <MDButton onClick={() => window.open("https://racecarcr.com/#preguntasfrecuentes", "_blank")} variant="contained" color='light'>
                                            <Icon>help_outline</Icon>
                                            <MDTypography color='dark' variant='h6' ml={1} sx={{ display: 'inline', verticalAlign: 'middle' }}>
                                                Preguntas Frecuentes
                                            </MDTypography>
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </DefaultCard>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Contactenos;
